<template>
	<div
		ref="dashboardRef"
		:style="`${zoomStyle}`"
		fluid
		class="dashboard-main custom-bthrust-dashboard"
		@drag="(e) => moveDragElement(e)"
		:key="windowWidth"
	>
		<!-- :style="`transform: scale(0.8);transform-origin: 0 0;margin-right:-300px;margin-bottom:-500px`" -->
		<VueDraggable
			tag="div"
			v-model="elements"
			class="element-drag-group row"
			draggable=".element--drag"
			handle=".card-drag-handle"
			ghost-class="element--ghost"
			animation="200"
			group="element--drag"
			v-on:start="drag = true"
			v-on:end="drag = false"
			v-on:change="change_drag"
		>
			<transition-group
				tag="div"
				type="transition"
				:name="!drag ? 'flip-list' : null"
				v-for="(element, index) in elements"
				:key="'element' + index + windowWidth"
				:class="`element--drag flex-grow-1 pa-3`"
				:style="`width:${getWidth(element.width)}`"
			>
				<component :is="element.components" :key="'component' + index"></component>
			</transition-group>
		</VueDraggable>
	</div>
</template>
<script>
import {
	GET_CLAIMS,
	GET_LEAVES,
	GET_PROJECTS,
	GET_MEETINGS,
	GET_SALES,
	GET_CLOSED_LEADS,
	GET_ALL_LEADS,
	GET_LISTING_PROJECTS,
	GET_LISTING_LEAD_SALES,
} from "@/core/services/store/dashboard.module.js";

const ClaimsCount = () => import("@/view/pages/dashboard/components/ClaimsCount");
const LeaveCount = () => import("@/view/pages/dashboard/components/LeaveCount");
const LeadOverview = () => import("@/view/pages/dashboard/components/LeadOverview");
const ProjectOverview = () => import("@/view/pages/dashboard/components/ProjectOverview");
const ClosedLeadSource = () => import("@/view/pages/dashboard/components/ClosedLeadSource");
const ActiveLeadSource = () => import("@/view/pages/dashboard/components/ActiveLeadSource");
const MeetingOverview = () => import("@/view/pages/dashboard/components/MeetingOverview");
const SalesOverview = () => import("@/view/pages/dashboard/components/SalesOverview");
const AllProjectList = () => import("@/view/pages/dashboard/components/AllProjectList");
const CustomerCount = () => import("@/view/pages/dashboard/components/CustomerCount");

export default {
	name: "Dashboard",
	data() {
		return {
			windowWidth: window.innerWidth,
			zoomStyle: "",
			drag: false,
			elements: [
				{ components: ClaimsCount, width: "33%" },
				{ components: LeaveCount, width: "33%" },
				{ components: LeadOverview, width: "33%" },
				// { components: CustomerCount, width: "33%" },
				{ components: ProjectOverview, width: "100%" },
				{ components: ClosedLeadSource, width: "50%" },
				{ components: ActiveLeadSource, width: "50%" },
				{ components: MeetingOverview, width: "100%" },
				{ components: SalesOverview, width: "100%" },
				{ components: AllProjectList, width: "100%" },
			],
		};
	},
	methods: {
		getWidth(col) {
			const width = window.innerWidth;
			if (width < 1366) {
				if (col == "33%") return "50%";
				if (col == "50%") return "100%";
			}
			return col;
		},
		change_drag() {
			// code here...
		},
		moveDragElement: (e) => {
			const windowHeight = window.outerHeight;
			if (e.screenY < 300) {
				const top = -(300 - e.screenY);
				window.scrollBy({
					top: top,
					left: 0,
					behavior: "smooth",
				});
			}
			if (e.screenY > windowHeight - 100) {
				const top = e.screenY - (windowHeight - 100);
				console.log({ top });
				window.scrollBy({
					top: top,
					left: 0,
					behavior: "smooth",
				});
			}
		},
		init() {
			this.$store.dispatch(GET_CLAIMS, {
				filter: "this_month",
			});
			this.$store.dispatch(GET_LEAVES, {
				filter: "this_month",
			});
			this.$store.dispatch(GET_PROJECTS, {
				filter: "this_month",
				user_filter: null,
			});
			this.$store.dispatch(GET_MEETINGS, {
				filter: "this_month",
				user_filter: null,
			});
			this.$store.dispatch(GET_SALES, {
				filter: "this_month",
				user_filter: null,
			});
			this.$store.dispatch(GET_CLOSED_LEADS, {
				filter: "this_month",
				user_filter: null,
			});
			this.$store.dispatch(GET_ALL_LEADS, {
				status: "all",
				filter: "this_month",
				user_filter: null,
			});
			this.$store.dispatch(GET_LISTING_PROJECTS, {
				status: "recurring",
				filter: "this_year",
				user_filter: null,
			});
			this.$store.dispatch(GET_LISTING_LEAD_SALES, {
				filter: "this_week",
			});
		},
		UpdateColumn() {
			/* this.windowWidth = window.innerWidth;
			if (this.windowWidth > 1540) {
				this.zoomStyle = "zoom:initial";
				// this.zoomStyle = ``
			}
			if (this.windowWidth <= 1540) {
				this.zoomStyle = "zoom:75%";
				// this.zoomStyle = `transform: scale(0.75);transform-origin: 0 0;margin-right:-400px;margin-bottom:-500px`
			}
			if (this.windowWidth <= 1366) {
				this.zoomStyle = "zoom:65%";
				// this.zoomStyle = `transform: scale(0.65);transform-origin: 0 0;margin-right:-550px;margin-bottom:-500px`
			} */
		},
	},
	components: {
		ClaimsCount,
		LeaveCount,
		ProjectOverview,
		MeetingOverview,
		SalesOverview,
		LeadOverview,
		AllProjectList,
		ActiveLeadSource,
		ClosedLeadSource,
		CustomerCount,
		VueDraggable: () => import("vuedraggable"),
		ProjectRecurringList: () => import("@/view/pages/dashboard/components/ProjectRecurringList"),
		ProjectActivityList: () => import("@/view/pages/dashboard/components/ProjectActivityList"),
		ProjectCancelledList: () => import("@/view/pages/dashboard/components/ProjectCancelledList"),
		ProjectLowPerformanceList: () =>
			import("@/view/pages/dashboard/components/ProjectLowPerformanceList"),
		ProjectHighPerformanceList: () =>
			import("@/view/pages/dashboard/components/ProjectHighPerformanceList"),
		ProjectHoldList: () => import("@/view/pages/dashboard/components/ProjectHoldList"),
		TotalUsers: () => import("@/view/pages/dashboard/components/TotalUsers"),
		TotalRevenue: () => import("@/view/pages/dashboard/components/TotalRevenue"),
		OrderState: () => import("@/view/pages/dashboard/components/OrderState"),
		SalesAnalytics: () => import("@/view/pages/dashboard/components/SalesAnalytics"),
		Revenue: () => import("@/view/pages/dashboard/components/Revenue"),
		Revenue2: () => import("@/view/pages/dashboard/components/Revenue2"),
		DealStatus: () => import("@/view/pages/dashboard/components/DealStatus"),
		Viewdetails: () => import("@/view/pages/dashboard/components/Viewdetails"),
		TeamMember: () => import("@/view/pages/dashboard/components/TeamMember.vue"),
		Permission: () => import("@/view/module/permission/Permission"),
		MyActivity: () => import("@/view/pages/dashboard/components/MyActivity"),
		OverAllStats: () => import("@/view/pages/dashboard/components/OverAllStats"),
		CalenderEvent: () => import("@/view/pages/dashboard/components/CalenderEvent.vue"),
	},
	mounted() {
		this.init();
		this.UpdateColumn();
		const self = this;
		window.addEventListener("resize", () => self.UpdateColumn());
	},
	beforeDestroy() {
		const self = this;
		window.removeEventListener("resize", () => self.UpdateColumn());
	},
};
</script>
<style lang="scss">
.v-application .element--ghost {
	> .card,
	.v-card {
		border: 1px dashed grey !important;
	}
}
</style>
